
import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Prop, Vue } from 'vue-property-decorator'
import AutoCompleter, { AutocompleteProvider } from '@/components/inputs/AutoCompleter.vue'
import CategoryCard from '@/components/collections/category/CategoryCard.vue'
import FormField from '@/components/forms/FormField.vue'
import { AutocompleteResult } from '@/components/forms/fields/FieldAutocomplete.vue'

export interface CategoryAutocompleteResult extends AutocompleteResult {
  Category: CategoryModel;
  FlattenedParents: CategoryModel[];
}

interface CategoryAutocompleteProvider extends AutocompleteProvider<CategoryAutocompleteResult> {
  FlattenParents (model: CategoryModel): CategoryModel[];
}

@Component({
  components: {
    AutoCompleter,
    CategoryCard,
    FormField
  }
})

export default class CategoryEditor extends Vue {
  // Results
  public searchTerm = ''
  public minTermLength = 3;
  private results: CategoryAutocompleteResult[] = []
  private loading = false

  // private branchId: string = this.$store.state.identity.BusinessBranch.BusinessBranchId
  // private categories: CategoryModel[] = this.$store.state.identity.BusinessBranch.Categories;

  @Prop({ required: true })
  private value!: CategoryModel[];

  @Prop({ default: 1 })
  private readonly maxSelections!: number;

  get isDisabled (): boolean {
    if (this.value && this.value.length)
    {
      return this.maxSelections >= this.value.length
    }
    else {
      return false
    }
  }

  private categoryProvider: CategoryAutocompleteProvider = {
    FlattenParents: function (model: CategoryModel): CategoryModel[] {
      function flatten (cat: CategoryModel): CategoryModel[] {
        if (cat.Parent === null) return [cat]
        const arr = flatten(cat.Parent)
        arr.unshift(cat)
        return arr
      }
      const flattened = flatten(model).reverse()
      flattened.pop()
      return flattened
    },
    Search: function (term: string): Promise<CategoryAutocompleteResult[]> {
      return (Services.API.Categories.AutocompleteCategoryiesOnly(term)
        .then((returnModels) => {
          return returnModels.map((result) => {
            return {
              Label: result.Name,
              Value: result.CategoryId,
              Category: result,
              FlattenedParents: this.FlattenParents(result)
            } as CategoryAutocompleteResult
          }) as CategoryAutocompleteResult[]
        })
      )
    },
    Suggest: function (term: string): Promise<string|null> {
      console.log(term)
      throw new Error('Function not implemented.')
    }
  }

  // Selections
  onResultSelected (selection: CategoryAutocompleteResult): void {
    this.$emit('selectedCategory', selection.Category)
  }

  removeCategory (categoryId: string): void {
    this.$emit('removeCategory', categoryId)
  }
}
