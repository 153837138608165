
import { InputText, InputTextarea } from '@/components/inputs'
import { FormPage } from '@/mixins'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'

@Component({
  components: {
    InputText,
    InputTextarea
  }
})

export default class BrandingEditor extends Mixins(FormPage) {
  @Prop({ required: true })
  private value!: BusinessBranchModel;

  // private branch = this.$store.state.identity.BusinessBranch;

  private headlineDebounce: number | undefined = undefined
  updateHeadline (): void {
    window.clearTimeout(this.headlineDebounce)
    if (!this.value) return
    this.headlineDebounce = window.setTimeout(() => {
      Services.API.Businesses.UpdateHeadline(this.value.BusinessBranchId, { Tagline: this.value.Tagline })
    }, 1000)
  }

  private bodyDebounce: number | undefined = undefined
  updateBody (): void {
    window.clearTimeout(this.bodyDebounce)
    if (!this.value) return
    this.bodyDebounce = window.setTimeout(() => {
      Services.API.Businesses.UpdateBody(this.value.BusinessBranchId, { Body: this.value.Body })
    }, 1000)
  }
}

