
import { InputText } from '@/components/inputs'
import InfoState from '@/components/status/InfoState.vue'
import { FormPage } from '@/mixins'
import LicenseQualificationReadDto from 'tradingmate-modules/src/models/api/businesses/branches/LicenseQualification/LicenseQualificationReadDto'
import LicenseQualificationUpsertDto from 'tradingmate-modules/src/models/api/businesses/branches/LicenseQualification/LicenseQualificationUpsertDto'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Prop, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    InputText,
    InfoState
  }
})

export default class LicenseQualificationEditor extends Mixins(FormPage) {
  @Prop({ required: true })
  private readonly branchId!: string;

  @Prop({ required: true })
  private readonly value!: LicenseQualificationReadDto[];

  // purivate branchId: string = this.$store.state.identity.BusinessBranch?.BusinessBranchId ?? ''
  // private licensesQualifications: LicenseQualificationReadDto[] = this.$store.state.identity.BusinessBranch?.LicensesQalifications ?? []

  licenseQualificationCreate (): void {
    if (!this.branchId) return
    this.loading = true
    const licenseQualification: LicenseQualificationUpsertDto = { LicenseQualification: '' }
    Services.API.Businesses.LicenseQualificationCreate(this.branchId, licenseQualification)
      .then((returnModel) => {
        this.$emit('input', [...this.value, returnModel])
        // this.licensesQualifications.push(returnModel)
      })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.loading = false })
  }

  private updateDebounce: number | undefined = undefined
  licenseQualificationUpdate (licenseQualification: LicenseQualificationReadDto): void {
    if (!this.branchId) return
    window.clearTimeout(this.updateDebounce)
    this.updateDebounce = window.setTimeout(() => {
      const updateDto: LicenseQualificationUpsertDto = { LicenseQualification: licenseQualification.LicenseQualification }
      this.httpError = null
      Services.API.Businesses.LicenseQualificationUpdate(this.branchId, licenseQualification.LicenseQualificationId, updateDto)
        .catch((errors) => { this.httpError = errors })
    }, 1000)
  }

  licenseQualificationDelete (licenseQualification: LicenseQualificationReadDto): void {
    if (!this.branchId) return
    Services.API.Businesses.LicenseQualificationDelete(this.branchId, licenseQualification.LicenseQualificationId)
      .then((deleted) => {
        const newValue = this.value
        const index = newValue.findIndex((item) => item.LicenseQualificationId === deleted.LicenseQualificationId)
        newValue.splice(index, 1)
        this.$emit('input', newValue)
      })
  }
}

