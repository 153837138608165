
import { InputText } from '@/components/inputs'
import { FormPage } from '@/mixins'
import ServiceOfferedReadDto from 'tradingmate-modules/src/models/api/businesses/branches/ServiceOffered/ServiceOfferedReadDto'
import ServiceOfferedUpsertDto from 'tradingmate-modules/src/models/api/businesses/branches/ServiceOffered/ServiceOfferedUpsertDto'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Prop, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    InputText
  }
})

export default class ServiceOfferedEditor extends Mixins(FormPage) {
  @Prop({ required: true })
  private readonly branchId!: string;

  // private branchId: string = this.$store.state.identity.BusinessBranch?.BusinessBranchId ?? ''
  // private servicesOffered: ServiceOfferedReadDto[] = this.$store.state.identity.BusinessBranch?.ServicesOffered ?? []

  @Prop({ required: true })
  private readonly value!: ServiceOfferedReadDto[]

  handleServiceOfferedCreate (): void {
    if (!this.branchId) return
    this.loading = true
    const serviceOffered: ServiceOfferedUpsertDto = { ServiceOffered: '' }
    Services.API.Businesses.ServiceOfferedCreate(this.branchId, serviceOffered)
      .then((returnModel) => {
        const newValue = [...this.value, returnModel]
        this.$emit('input', newValue)
      })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.loading = false })
  }

  private updateDebounce: number | undefined = undefined
  serviceOfferedUpdate (service: ServiceOfferedReadDto): void {
    if (!this.branchId) return
    window.clearTimeout(this.updateDebounce)
    this.updateDebounce = window.setTimeout(() => {
      const updateDto: ServiceOfferedUpsertDto = { ServiceOffered: service.ServiceOffered }
      this.httpError = null
      Services.API.Businesses.ServiceOfferedUpdate(this.branchId, service.ServiceOfferedId, updateDto)
        .catch((errors) => { this.httpError = errors })
    }, 1000)
  }

  serviceOfferedDelete (service: ServiceOfferedReadDto): void {
    if (!this.branchId) return
    Services.API.Businesses.ServiceOfferedDelete(this.branchId, service.ServiceOfferedId)
      .then((deletedService) => {
        const index = this.value.findIndex((item) => item.ServiceOfferedId === deletedService.ServiceOfferedId)
        const newValue = this.value
        newValue.splice(index, 1)
        this.$emit('input', newValue)
      })
  }
}

